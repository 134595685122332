const search = "http://10.74.18.108:8080/db/bmkgsatu/@search?type_name=Propinsi&propinsi_name=sumatera&_metadata=propinsi_code,propinsi_name,region_id,region_description";
import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "./helper";
import { getNodeFromPath } from "tree-component";
var BmkgEntry = {
    Aerosol: "is_aerosol",
    Kah: "is_kimia_air_hujan",
    So2no2: "is_so2_no2",
    Spm: "is_spm",
    Agm1a: "is_agm1a",
    Agm1b: "is_agm1b",
    Aktinograph: "is_aktinograph",
    Barograph: "is_barograph",
    Fenologi: "is_fenologi",
    Fklim: "is_fklim",
    FormHujan: "is_form_hujan",
    FormPerawanan: "is_perawanan",
    Gunbellani: "is_gunbellani",
    IklimMikro: "is_iklim_mikro",
    HujanHellman: "is_hujan_helman",
    KelembabanTanah: "is_kelembapan_tanah",
    LamaPenyinaran: "is_lama_penyinaran",
    Lysimeter: "is_lysimeter",
    OpPenguapan: "is_op_penguapan",
    PichePenguapan: "is_piche_penguapan",
    PsycrometerAssman: "is_psychrometer_assman",
    SuhuTanah: "is_suhu_tanah",
    Thermohigograph: "is_thermohigograph",
    MetarSpeci: "is_metar_speci",
    Pibal: "is_pibal",
    sinoptik: "is_me48",
};
export default {
    //autoQC
    getMetaQcAuto(path) {
        const url = helper.getBaseUrl() + path;
        return axios.get(url, helper.getConfig());
    },
    ////exportData==========================================
    getExStasiunByBalai(reg, is_entry) {
        // console.log("is entry : ",is_entry)
        var entry = BmkgEntry[is_entry];

        var ids;
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin.hasOwnProperty("groupIds")) {
            ids = userLogin.groupIds;
        }
        let userType = userLogin.username;
        var url;
        if (userType == "root") {
            url =
                helper.getBaseUrl() +
                "@aggregation_query?type_name=BmkgStation&region_id=" +
                reg +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000";
        } else {
            url =
                helper.getBaseUrl() +
                "@aggregation_multiquery?type_name=BmkgStation&region_id=" +
                reg +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000&station_id=" +
                ids;
        }
        return axios.get(url, helper.getConfig());
    },
    getExStasiunByProv(prov, is_entry) {
        var entry = BmkgEntry[is_entry];
        var ids;
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin.hasOwnProperty("groupIds")) {
            ids = userLogin.groupIds;
        }
        let userType = userLogin.username;
        var url;
        if (userType == "root") {
            url =
                helper.getBaseUrl() +
                "@aggregation_query?type_name=BmkgStation&propinsi_id=" +
                prov +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000";
        } else {
            url =
                helper.getBaseUrl() +
                "@aggregation_multiquery?type_name=BmkgStation&propinsi_id=" +
                prov +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000&station_id=" +
                ids;
        }
        return axios.get(url, helper.getConfig());
    },
    getExStasiunByKab(kab, is_entry) {
        var entry = BmkgEntry[is_entry];
        var ids;
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        if (userLogin.hasOwnProperty("groupIds")) {
            ids = userLogin.groupIds;
        }
        let userType = userLogin.username;
        var url;
        if (userType == "root") {
            url =
                helper.getBaseUrl() +
                "@aggregation_query?type_name=BmkgStation&kabupaten_id=" +
                kab +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000";
        } else {
            url =
                helper.getBaseUrl() +
                "@aggregation_multiquery?type_name=BmkgStation&kabupaten_id=" +
                kab +
                "&" +
                entry +
                "=true" +
                "&_metadata=station_id,station_wmo_id,alias_station_id,station_name,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,path&_size=10000&station_id=" +
                ids;
        }
        return axios.get(url, helper.getConfig());
    },

    ///==========================get ALIAS =    ===============================
    async getAllias(form) {
        const url = helper.getBaseUrl() + "@parameter_alias?resource=" + form + "&_size=10000";
        let list_allias = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                list_allias = data;
                resolve(list_allias);
            })
            .catch(function (error) {});
        return list_allias;
    },

    ////===========================Region=======================================
    getRegionList() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgRegion&_metadata=region_id,region_description,id";
        return axios.get(url, helper.getConfig());
    },
    postRegion(body) {
        const url = helper.getBaseUrl();
        return axios.post(url, body, helper.getConfig());
    },
    patchRegion(body, regionID) {
        const url = helper.getBaseUrl() + regionID;
        return axios.patch(url, body, helper.getConfig());
    },
    deleteRegion(regionID) {
        const url = helper.getBaseUrl() + regionID;
        return axios.delete(url, helper.getConfig());
    },
    ////===========================Provinsi=======================================
    // deleteProvinsi(provinsiID){
    //     const url = helper.getBaseUrl()+provinsiID;
    //     // const res = await  axios.delete(helper.getBaseUrl()+val,helper.getConfig())
    //     return axios.delete(url, helper.getConfig())
    // },
    getPropinsiListByRegion(region_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?region_id=" + region_id + "&type_name=Propinsi&_metadata=region_id,region_description,propinsi_id,propinsi_name,propinsi_code,id,path&_size=100";
        return axios.get(url, helper.getConfig());
    },
    getPropinsiList() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=Propinsi&_metadata=region_id,region_description,propinsi_id,propinsi_name,propinsi_code,id,path&_size=1000";
        return axios.get(url, helper.getConfig());
    },

    postPorpinsi(body, path) {
        const url = helper.getBaseUrl() + "/" + path;
        return axios.post(url, body, helper.getConfig());
    },
    patchPorpinsi(body, path) {
        const url = helper.getBaseUrl() + path;
        return axios.patch(url, body, helper.getConfig());
    },
    deletePropinsi(path) {
        const url = helper.getBaseUrl() + path;
        return axios.delete(url, helper.getConfig());
    },
    ////===========================Kabupaten=======================================
    getKabupatenListByRegion(region_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?region_id=" + region_id + "&type_name=Kabupaten&_metadata=region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,id,path&_size=1000";
        return axios.get(url, helper.getConfig());
    },
    getKabupatenListByPropinsi(propinsi_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?propinsi_id=" + propinsi_id + "&type_name=Kabupaten&_metadata=region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,kabupaten_code,id,path&_size=1000";
        return axios.get(url, helper.getConfig());
    },
    getKabupatenList() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=Kabupaten&_metadata=region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name,id,path&_size=10000";
        return axios.get(url, helper.getConfig());
    },
    postKabupaten(body, path) {
        const url = helper.getBaseUrl() + path;
        return axios.post(url, body, helper.getConfig());
    },
    patchKabupaten(body, path) {
        const url = helper.getBaseUrl() + path;
        return axios.patch(url, body, helper.getConfig());
    },

    ////===========================Stasiun=======================================
    getStasiunListByKabupaten(kabupaten_id) {
        const url =
            helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&kabupaten_id=" + kabupaten_id + "&_metadata=station_platform_uid,station_name,kabupaten_id,kabupaten_name,propinsi_id,propinsi_name,region_id,region_description,id,path";
        return axios.get(url, helper.getConfig());
    },
    postStasiun(body, path) {
        const url = helper.getBaseUrl() + path;
        return axios.post(url, body, helper.getConfig());
    },
    getAllStasiun() {
        const url =
            helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000";
        return axios.get(url, helper.getConfig());
    },
    getAllStasiunWithIDName() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&_metadata=station_id,station_name&_size=10000";
        return axios.get(url, helper.getConfig());
    },
    getAllStationWithSearch(id1, id2, options) {
        let url =
            helper.getBaseUrl() +
            "@search?type_name=BmkgStation&_sort_asc=station_id&station_id__gte=" +
            id1 +
            "&station_id__lte=" +
            id2 +
            "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name&_size=200";

        if (options != "") {
            url = helper.getBaseUrl() + "@search?type_name=BmkgStation&_sort_asc=station_id&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name" + options + "&_size=10000";
        }
        // const url = helper.getBaseUrl()+"@search?type_name=BmkgStation&_sort_asc=station_id&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name"
        return axios.get(url, helper.getConfig());
    },

    getAllStationWithParams(id1, id2, options, userType, ids, isUsage) {
        let url = "";
        if (userType == "root") {
            if (isUsage) {
                url = helper.getBaseUrl() + "@aggregation_query?type_name=MetaStationUsage&_size=10000&_metadata=station_id,station_wmo_id,propinsi_id,propinsi_name,station_name,path,usage_station_id" + options;
            } else {
                url =
                    helper.getBaseUrl() +
                    "@search?type_name=BmkgStation&_sort_asc=station_id&station_id__gte=" +
                    id1 +
                    "&station_id__lte=" +
                    id2 +
                    "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name,is_poshujan,pos_hujan_id&_size=200";

                if (options != "") {
                    url =
                        helper.getBaseUrl() + "@search?type_name=BmkgStation&_sort_asc=station_id&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name,is_poshujan,pos_hujan_id" + options + "&_size=10000";
                }
            }
        } else {
            if (isUsage) {
                url = helper.getBaseUrl() + "@aggregation_query?type_name=MetaStationUsage&_size=10000&_metadata=station_id,station_wmo_id,propinsi_id,propinsi_name,station_name,path,usage_station_id" + options + "&station_id=" + ids;
            } else {
                url =
                    helper.getBaseUrl() +
                    "@aggregation_multiquery?type_name=BmkgStation&_sort_asc=station_id&station_id__gte=" +
                    id1 +
                    "&station_id__lte=20000&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name,is_poshujan,pos_hujan_id&station_id=" +
                    ids +
                    "&_size=10000";

                if (options != "") {
                    url =
                        helper.getBaseUrl() +
                        "@aggregation_multiquery?type_name=BmkgStation&_sort_asc=station_id&station_id__gte=" +
                        id1 +
                        "&station_id__lte=20000&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name,is_poshujan,pos_hujan_id" +
                        options +
                        "&station_id=" +
                        ids +
                        "&_size=10000";
                    // url = helper.getBaseUrl()+"@search?type_name=BmkgStation&_sort_asc=station_id&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name"+options+"&_size=10000&station_id="+ids+""
                }
            }
        }

        // const url = helper.getBaseUrl()+"@search?type_name=BmkgStation&_sort_asc=station_id&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,propinsi_id,propinsi_name"
        return axios.get(url, helper.getConfig());
    },

    getAllStationWithMetaStation() {
        let url = helper.getBaseUrl() + "@aggregation_query?type_name=MetaStation&_size=10000&_metadata=station_id,station_name,station_wmo_id,station_elevation,path_station,propinsi_id,propinsi_name,is_poshujan,pos_hujan_id";
        return axios.get(url, helper.getConfig());
    },

    getStationWithLimit() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&_from=0&_sort_asc=station_id&_size=200&_metadata=station_id,path,station_name,station_wmo_id,station_elevation,path,current_latitude,current_longitude";
        return axios.get(url, helper.getConfig());
    },

    getStationByPath(path) {
        const url = helper.getBaseUrl() + path;
        return axios.get(url, helper.getConfig());
    },

    getStasiunByRegion(id) {
        // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&region_id="+id+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000"
        // return axios.get(url, helper.getConfig())
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url =
                helper.getBaseUrl() +
                "/@aggregation_query?type_name=BmkgStation&region_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            const url =
                helper.getBaseUrl() +
                "/@aggregation_multiquery?type_name=BmkgStation&region_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&station_id=" +
                ids +
                "&_size=15000&station_id__gte=1";
            // const url = helper.getBaseUrl()+"/@aggregation_multiquery?type_name=BmkgStation&kabupaten_id="+id+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000&station_id="+ids+"&_size=1000&station_id__gte=1&station_id__lte=500"
            return axios.get(url, helper.getConfig());
        }
    },

    getStasiunWithIDNamebyRegion(region_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&region_id=" + region_id + "&_metadata=station_id,station_name&_size=10000";
        return axios.get(url, helper.getConfig());
    },

    getStasiunWithWMObyRegion(region_id) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";
        let url = "";
        if (userType == "root") {
            url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&region_id=" + region_id + "&station_wmo_id__not=0&_metadata=station_id,station_wmo_id,station_name&_size=10000";
            if (!region_id) {
                url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_wmo_id,station_name&_size=10000";
            }
        } else {
            url = helper.getBaseUrl() + "/@aggregation_multiquery?type_name=BmkgStation&region_id=" + region_id + "&station_wmo_id__not=0&_metadata=station_id,station_wmo_id,station_name&_size=10000";
            if (!region_id) {
                url = helper.getBaseUrl() + "/@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_wmo_id,station_name&_size=10000";
            }
        }
        return axios.get(url, helper.getConfig());
    },

    getStasiunByPropinsi(id) {
        // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&propinsi_id="+id+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000"
        // return axios.get(url, helper.getConfig())
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url =
                helper.getBaseUrl() +
                "/@aggregation_query?type_name=BmkgStation&propinsi_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=15000";
            // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&kabupaten_id="+id+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000"
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            const url =
                helper.getBaseUrl() +
                "/@aggregation_multiquery?type_name=BmkgStation&propinsi_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&station_id=" +
                ids +
                "&_size=15000&station_id__gte=1";
            return axios.get(url, helper.getConfig());
        }
    },

    getStasiunWithIDNamebyPropinsi(propinsi_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&propinsi_id=" + propinsi_id + "&_metadata=station_id,station_name&_size=10000";
        return axios.get(url, helper.getConfig());
    },

    getStasiunByKabupaten(id) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url =
                helper.getBaseUrl() +
                "/@aggregation_query?type_name=BmkgStation&kabupaten_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=15000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }
            const url =
                helper.getBaseUrl() +
                "/@aggregation_multiquery?type_name=BmkgStation&kabupaten_id=" +
                id +
                "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=15000&station_id=" +
                ids +
                "&_size=15000&station_id__gte=1";
            // const url = helper.getBaseUrl()+"@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id,station_elevation,path&station_id="+ids+"&_size=1000&station_id__gte=1&station_id__lte=500"
            return axios.get(url, helper.getConfig());
        }

        // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&kabupaten_id="+id+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,path,region_id,region_description,propinsi_id,propinsi_name,kabupaten_id,kabupaten_name&_size=10000"
        // return axios.get(url, helper.getConfig())
    },

    getAllStasiunByWmo(wmoid) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        // if (userType == "root") {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_wmo_id__not=0&station_wmo_id=" + wmoid + "&_metadata=station_id,station_name,station_wmo_id,station_elevation,path&_size=10000";

        return axios.get(url, helper.getConfig());

        // } else {
        //   if (userLogin.hasOwnProperty("groupIds")) {
        //     ids = userLogin.groupIds;
        //   }

        //   const url =
        //     helper.getBaseUrl() +
        //     "@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&station_wmo_id="+wmoid+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,current_longitude,current_latitude,path&_size=1000&station_id_gte=1&station_id_lte=1000";
        //     // "@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&station_wmo_id="+wmoid+"&_metadata=station_id,station_name,station_wmo_id,station_elevation,current_longitude,current_latitude,path&station_id=1&_size=1000&station_id_gte=1&station_id_lte=1000";

        //     return axios.get(url, helper.getConfig());
        // }
    },

    getAllStasiunWithWMO() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id,station_elevation,path&_size=15000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }

            const url =
                helper.getBaseUrl() +
                "@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id,station_elevation,current_longitude,current_latitude,path&station_id=" +
                ids +
                "&_size=15000&station_id_gte=1&station_id_lte=15000";
            return axios.get(url, helper.getConfig());
        }
    },

    getAllStasiunWithWMOandMetaStation() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url = helper.getBaseUrl() + "/@aggregation_query?type_name=MetaStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id,station_elevation,path_station&_size=15000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }

            const url = helper.getBaseUrl() + "@aggregation_multiquery?type_name=MetaStation&station_wmo_id__not=0&_metadata=station_id,station_name,station_wmo_id,station_elevation,path_station&station_id=" + ids + "&_size=15000";
            //&station_id__gte=1&station_id__lte=500";
            return axios.get(url, helper.getConfig());
        }
    },

    getAllStasiunWithWMOElevation() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_wmo_id__not=0&station_elevation=!0&is_pibal=true&_metadata=station_id,station_name,station_wmo_id,station_elevation,path&_size=15000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }

            const url = helper.getBaseUrl() + "@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&is_pibal=true&_metadata=station_id,station_name,station_wmo_id,station_elevation,path&station_id=" + ids + "&_size=15000";
            //&station_id__gte=1&station_id__lte=500";
            return axios.get(url, helper.getConfig());
        }
    },
    getAllStasiunWithWIcao() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&station_icao__not=null&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&_size=1000"
            const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_icao__not=null&is_metar_speci=1&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&_size=10000";
            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }

            // const url = helper.getBaseUrl()+"@aggregation_multiquery?type_name=BmkgStation&station_icao__not=null&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&station_id="+ids+"&_size=1000&station_id__gte=1&station_id__lte=500"
            const url =
                helper.getBaseUrl() + "@aggregation_multiquery?type_name=BmkgStation&station_icao__not=null&is_metar_speci=1&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&station_id=" + ids + "&_size=15000";
            //&station_id__gte=1&station_id__lte=500";
            return axios.get(url, helper.getConfig());
        }
    },

    getPathStasiun(station_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_id=" + station_id + "&_metadata=station_id,station_wmo_id,station_elevation,path";
        return axios.get(url, helper.getConfig());
    },

    getPath(type, station_id, path) {
        const url = helper.getBaseUrl() + path + "/@aggregation_query?type_name=" + type + "&station_id=" + station_id + "&_metadata=station_id,station_wmo_id,station_elevation,path";
        return axios.get(url, helper.getConfig());
    },
    getPathwithStation(pathStation, type, station_id) {
        const url = helper.getBaseUrl() + pathStation + "/@aggregation_query?type_name=" + type + "&station_id=" + station_id + "&_metadata=station_id,station_wmo_id,station_elevation,path";
        return axios.get(url, helper.getConfig());
    },
    // getPathKabupaten(propinsi_id){
    //     const url = helper.getBaseUrl()+"/@aggregation_query?type_name=Kabupaten&propinsi_id="+propinsi_id+"&_metadata=kabupaten_name,path"
    //     return axios.get(url, helper.getConfig())
    // },
    getPoshujanByKabupaten(station_id, kabupaten_id) {
        const url =
            helper.getBaseUrl() +
            "/@aggregation_query?type_name=BmkgStation&kabupaten_id=" +
            kabupaten_id +
            "&entry_station_id=" +
            station_id +
            "&is_poshujan=1&poshujan_is_active=1&_metadata=id,path,pos_hujan_id,station_name,poshujan_entry_station_id,poshujan_is_active,station_id,kabupaten_id";
        return axios.get(url, helper.getConfig());
    },
    getPoshujanByProvinsi(station_id, prov_id) {
        const url =
            helper.getBaseUrl() +
            "/@aggregation_query?type_name=BmkgStation&propinsi_id=" +
            prov_id +
            "&entry_station_id=" +
            station_id +
            "&is_poshujan=1&poshujan_is_active=1&_metadata=id,path,pos_hujan_id,station_name,poshujan_entry_station_id,poshujan_is_active,station_id,kabupaten_id";
        return axios.get(url, helper.getConfig());
    },
    getPoshujanByCoordinator(station_id) {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        let userType = userLogin.username;
        let ids = "";

        if (userType == "root") {
            // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&station_icao__not=null&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&_size=1000"
            // const url = helper.getBaseUrl()+"/@aggregation_query?type_name=BmkgStation&station_icao__not=null&is_metar_speci=1&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&_size=1000"
            const url =
                helper.getBaseUrl() +
                "@aggregation_query?type_name=BmkgStation&poshujan_is_active=1&poshujan_entry_station_id=" +
                station_id +
                "&is_form_hujan=true&_metadata=station_id,id,path,pos_hujan_id,station_name,poshujan_entry_station_id,poshujan_is_active,station_id,kabupaten_id,kabupaten_name,propinsi_id,propinsi_name&_size=100000";

            return axios.get(url, helper.getConfig());
        } else {
            if (userLogin.hasOwnProperty("groupIds")) {
                ids = userLogin.groupIds;
            }

            // const url = helper.getBaseUrl()+"@aggregation_multiquery?type_name=BmkgStation&station_icao__not=null&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&station_id="+ids+"&_size=1000&station_id__gte=1&station_id__lte=500"
            // const url = helper.getBaseUrl()+"@aggregation_multiquery?type_name=BmkgStation&station_icao__not=null&is_metar_speci=1&_metadata=station_id,station_name,station_wmo_id,station_icao,station_elevation,path&station_id="+ids+"&_size=1000&station_id__gte=1&station_id__lte=500"
            const url =
                helper.getBaseUrl() +
                "@aggregation_multiquery?type_name=BmkgStation&poshujan_is_active=1&poshujan_entry_station_id=" +
                station_id +
                "&is_form_hujan=true&station_id=" +
                ids +
                "&_metadata=station_id,id,path,pos_hujan_id,station_name,poshujan_entry_station_id,poshujan_is_active,station_id,kabupaten_id,kabupaten_name,propinsi_id,propinsi_name&_size=100000";

            return axios.get(url, helper.getConfig());
        }
        // const url = helper.getBaseUrl()+"@aggregation_query?type_name=BmkgStation&poshujan_is_active=1&poshujan_entry_station_id="+station_id+
        // "&is_form_hujan=true&_metadata=station_id,id,path,pos_hujan_id,station_name,poshujan_entry_station_id,poshujan_is_active,station_id,kabupaten_id,kabupaten_name,propinsi_id,propinsi_name&_size=100000"
        // return axios.get(url, helper.getConfig())
    },

    getStationPosHujan(station_id) {
        const url = helper.getBaseUrl() + "@aggregation_query?_size=10000&is_poshujan=1&type_name=BmkgStation&_metadata=station_id,station_name,pos_hujan_id&poshujan_entry_station_id=" + station_id;
        return axios.get(url, helper.getConfig());
    },

    async newObserver(post_data) {
        const url = helper.getBaseUrl() + "/observer";
        return await axios.post(url, post_data, helper.getConfig());
    },

    getObserver() {
        const observer = [
            { observer: "Ari Nuryadi", id: "1" },
            {
                observer: "Bonas Efendy",
                id: "2",
            },
            {
                observer: "Halomoan Nasution",
                id: "3",
            },
            {
                observer: "Indra kelana",
                id: "4",
            },
            {
                observer: "Bima Arifin",
                id: "5",
            },
            {
                observer: "Kim Jong Un",
                id: "6",
            },
            {
                observer: "Ariel Tatum",
                id: "7",
            },
            {
                observer: "Charlie",
                id: "8",
            },
        ];
        return observer;
    },

    async getObserverStation(station_id) {
        var url =
            helper.getBaseUrl() +
            // "/@search?type_name=Observer&station_id=" +
            "/@aggregation_query?type_name=Observer&station_id=" +
            station_id +
            "&_sort_asc=observer_name&_size=200&_metadata=id,observer_id,path,observer_nip,observer_name,observer_telephone,observer_is_active";
        var observers = [];
        await axios
            .get(url, helper.getConfig())
            .then(function (dataResponse) {
                // var dataItems = dataResponse.data.items;
                var dataItems = dataResponse.data;
                for (var i in dataItems) {
                    var observer = {
                        id: dataItems[i].observer_id,
                        observer: dataItems[i].observer_name,
                    };
                    observers.push(observer);
                }
                resolve(observers);
                // observers = dataResponse.data.items
            })
            .catch(function (error) {
                // resolve(observers);
            });
        return observers;
    },

    getObserverStationWithAggQuery(station_id) {
        const url =
            helper.getBaseUrl() +
            "/@aggregation_query?type_name=Observer&station_id=" +
            station_id +
            "&_size=200&_metadata=observer_id,station_id,path,observer_name,observer_nip,observer_telephone,observer_valid_from,observer_valid_to,observer_is_active";
        return axios.get(url, helper.getConfig());
    },

    getListMetaGeography(path) {
        const url = helper.getBaseUrl() + path + "/metageography/@items_list?include=id,geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to";
        return axios.get(url, helper.getConfig());
    },
    /**
     * Metadata QC/Form
     */
    patchStasiun(path, post_data) {
        const url = helper.getBaseUrl() + path;
        return axios.patch(url, post_data, helper.getConfig());
    },

    async updateStasiun(path, post_data) {
        return await new Promise(async (resolve) => {
            const url = helper.getBaseUrl() + path;
            var data = axios.patch(url, post_data, helper.getConfig());
            resolve(data);
        });
    },

    async getQCData(path, key) {
        const url = helper.getBaseUrl() + path;
        let qc_data = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                qc_data = data[key];
                resolve(qc_data);
            })
            .catch(function (error) {});
        return qc_data;
    },
    async getOprHours(path) {
        const url = helper.getBaseUrl() + path;
        let data_opr_hours = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                data_opr_hours = data;
                resolve(data_opr_hours);
            })
            .catch(function (error) {});
        return data_opr_hours;
    },
    async getReferensiData(path) {
        const url = helper.getBaseUrl() + path;
        let ref_data = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                ref_data = data;
                resolve(data);
            })
            .catch(function (error) {});
        return ref_data;
    },

    async getMetadataPibal(path, name) {
        const url = helper.getBaseUrl() + path + name;
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationOperatingHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_operating_hours";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationGtsClimatHeader(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.gts.IGTS.klimat_gts_berita_klimat_header";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationGtsWxrevHeader(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.gts.IGTS.wxrev_gts_header";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationStartHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_hour_start";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationEndHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_hour_end";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationMidStartHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_mid_hour_start";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationMidEndHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_mid_hour_end";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerAirRaksa(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_air_raksa";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerDigital(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_digital";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerDigital(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_digital";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getEntryFirstEnable(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/entry_first_enable";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getEntryFirstDate(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/entry_first_date";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                // console.log(response)
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationElement() {
        const url = helper.getBaseUrl() + "/@setting_aelement";
        return await axios.get(url, helper.getConfig());
    },
    async getFormInput(path) {
        const url = helper.getBaseUrl() + path;
        return await axios.get(url, helper.getConfig());
    },
    async getLogQC(path, form_type) {
        // const url = helper.getBaseUrl() + path + "/@search?type_name=LogQcChange&form_type="+form_type+"&_metadata=id,station_name,form_type,qc_type,qc_atribut,qc_old_value,qc_new_value,username,qc_timestamp&_size=10000"
        const url =
            helper.getBaseUrl() + path + "/@search?type_name=LogQcChange&form_type=" + form_type + "&_metadata=id,station_name,form_type,qc_type,qc_atribut,qc_old_value,qc_new_value,username,path,creation_date&_size=100&_sort_des=creation_date";

        return await axios.get(url, helper.getConfig());
    },

    async requestMetadataUsage(path, post_data) {
        const url = helper.getBaseUrl() + path;
        return await axios.post(url, post_data, helper.getConfig());
    },

    async listMetadataUsage(path) {
        const url = helper.getBaseUrl() + path + "/usage/@items_list?include=BmkgSatu.metadata.station.behavusage.IBehavStationUsage";
        return await axios.get(url, helper.getConfig());
        // let data = null;
        // await axios.get(url, helper.getConfig())
        //     .then(function (response) {
        //         return {data:response}
        //     })
        //     .catch(function (error) {
        //         return {data: null}
        //     });
    },

    async getListManageUsage() {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/@items_manageusage?include=manage_usage_id,manage_usage_name,manage_usage_status&page_size=100";
        return await axios.get(url, helper.getConfig());
    },

    async newManageUsage(post_data) {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/";
        return await axios.post(url, post_data, helper.getConfig());
    },

    async patchManageUsage(id, post_data) {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/" + id;
        return await axios.patch(url, post_data, helper.getConfig());
    },

    async listMetadataGeography(path) {
        const url = helper.getBaseUrl() + path + "/metageography/@items_list?include=geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to";
        return await axios.get(url, helper.getConfig());
    },

    // NIMOS
    async listNimosSynchronization() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=LogNimos&_metadata=nimos_sync_date,nimos_description,nimos_total_rows";
        return await axios.get(url, helper.getConfig());
    },

    async reportKAHExcel(station_id, year) {
        var year_lte = year + "-12-31T23:59";
        var year_gte = year + "-01-01T00:00";
        const url = helper.getBaseUrl() + "@report_kah?tahun_form=" + year + "&station_id=" + station_id;
        // const url = helper.getBaseUrl() + "@search?type_name=KAH&tgl_pasang__gte="+year_gte+"&tgl_pasang__lte="+year_lte+"station_id="+station_id;
        // const url = "http://guillotina.agrisoft.id:7080/db/bmkgsatu/@search?type_name=KAH&tgl_pasang__gte="+year_gte+"&tgl_pasang__lte="+year_lte;
        return await axios.get(url, helper.getConfig());
    },

    async reportKAHExcelNoWMO(station_id, year) {
        const url = helper.getBaseUrl() + "@report_kah_no?tahun_form=" + year + "&station_id=" + station_id;
        return await axios.get(url, helper.getConfig());
    },
    async reportSPMExcel(station_id, year) {
        const url = helper.getBaseUrl() + "@report_spm?tahun_form=" + year + "&station_id=" + station_id;
        return await axios.get(url, helper.getConfig());
    },
    async reportAerosolExcel(station_id, year) {
        const url = helper.getBaseUrl() + "@report_aerosol?tahun_form=" + year + "&station_id=" + station_id;
        return await axios.get(url, helper.getConfig());
    },
    async reportSO2NO2Excel(station_id, year) {
        const url = helper.getBaseUrl() + "@report_so2no2?tahun_form=" + year + "&station_id=" + station_id;
        return await axios.get(url, helper.getConfig());
    },
    async reportEanetExcel(station_id, year) {
        const url = helper.getBaseUrl() + "@report_eanet?tahun=" + year + "&station_id=" + station_id;
        return await axios.get(url, helper.getConfig());
    },
};
